import {Col, Form, Row, ToggleButton} from "react-bootstrap";
import {MittelModal} from "./Modal.js";
import React from "react";
import {APIClient} from "../utils/APIClient.js";
import useForm from "../hooks/useForm.js";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {fetchPromises} from "../utils/fetchPromises.js";
import crypto from "crypto";

function UserModal({setModal, user, refreshUsers, countries, brands}) {
    const {form, setForm, onInputChange} = useForm({
        countryId: countries && countries[0].id,
        ...user,
        password: undefined,
        passwordRequired: !user,
    });

    const onModalSaveClick = async () => {
        try {
            let password;
            if (form.password) {
                const shaSum = crypto.createHash('sha1');
                shaSum.update(form.password);
                password = shaSum.digest('hex');
            }

            await APIClient().saveUser({
                ...form,
                password
            });
            refreshUsers();
        } catch (e) {
            console.log(e);
        }
    }

    const onModalClose = () => {
        setModal({
            show: false
        });
    }

    return (
        <MittelModal show onClose={onModalClose} onCallback={onModalSaveClick} title="User">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    External ID
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="externalId" onChange={onInputChange} value={form.externalId || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Name
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" onChange={onInputChange} value={form.name || ''} required />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Username
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="username" onChange={onInputChange} value={form.username || ''} required />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Password
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="password" name="password" onChange={onInputChange} required={form.passwordRequired}/>
                    {form.passwordRequired &&
                        <Form.Control.Feedback type="invalid">
                            Field is required.
                        </Form.Control.Feedback>
                    }
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Country
                </Form.Label>
                <Col sm="8">
                    <Form.Select name="countryId" onChange={onInputChange} value={form.countryId}>
                        {countries && countries.map((c, index) =>
                            <option key={index} value={c.id}>{c.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Brands
                </Form.Label>
                <Col sm="8">
                    {brands && brands.map((b, index) => {
                        let name = b.brandName;
                        if (b.brandCategoryName) {
                            name += " - " + b.brandCategoryName;
                        }
                        return (
                            <ToggleButton
                                key={index}
                                className="me-1 mt-1"
                                id={`toggle-check-${b.brandId}-${b.brandCategoryId ? b.brandCategoryId : ''}`}
                                variant="outline-primary"
                                type="checkbox"
                                checked={form.brands && !!form.brands[b.brandId + "-" + (b.brandCategoryId ? b.brandCategoryId : '')]}
                                value={`[brands][${b.brandId}-${b.brandCategoryId ? b.brandCategoryId : ''}]`}
                                onChange={onInputChange}
                            >
                                {name}
                            </ToggleButton>
                        );
                    })}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Administrator
                </Form.Label>
                <Col sm="8">
                    <BootstrapSwitchButton
                        checked={!!form.admin}
                        onlabel="Yes"
                        offlabel="No"
                        width={70}
                        onChange={(checked) => {
                            setForm({
                                ...form,
                                admin: checked
                            })
                        }}
                    />
                </Col>
            </Form.Group>
        </MittelModal>
    );
}

export default fetchPromises({
    user: (props) => props.userId && ({ name: 'getUser', props: ['userId'] }),
    brands: () => ({ name: 'getBrandsWithBrandCategories' }),
    countries: () => ({ name: 'getCountries' })
}, false)(UserModal);