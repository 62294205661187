import React from 'react';
import ReactDOM from 'react-dom';
import './client/styles/index.scss';
import Mittel from './client/Mittel';
import {HashRouter} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <HashRouter>
          <Mittel />
      </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
