import {Link, Route, Switch, withRouter} from "react-router-dom";
import Users from "./pages/admin/UsersPage.js";
import {Button} from "react-bootstrap";
import mittelLogo from "./styles/assets/mittel.jpg";
import userIcon from "./styles/assets/icons/user.svg";
import logoutIcon from "./styles/assets/icons/logout.svg";
import menuIcon from "./styles/assets/icons/menu.svg";
import Countries from "./pages/admin/CoutriesPage.js";
import {ENUM} from "./utils/enum";
import BrandsPage from "./pages/admin/BrandsPage.js";
import OrdersPage from "./pages/admin/OrdersPage.js";
import ArticlesPage from "./pages/admin/ArticlesPage.js";
import BrandPage from "./pages/front/BrandPage.js";
import Menu from "./components/Menu";
import {AlertProvider} from "./contexts/alertContext";
import useAuth from "./hooks/useAuth";
import {fetchPromises} from "./utils/fetchPromises.js";
import {UserProvider} from "./contexts/userContext";
import {useState} from "react";
import {ConfirmModalProvider} from "./components/ConfirmModalProvider.js";
import OrdersHistoryPage from "./pages/front/OrdersHistoryPage";
import ImportExportPage from "./pages/admin/ImportExportPage.js";

function Mittel({location, user, history}) {
    const {login, logout} = useAuth(location, history);
    const [showMenu, setShowMenu] = useState(false);
    const paths = location.pathname.split('/');
    const isAdmin = user && user.admin === 'true';

    return (
        <UserProvider user={user} login={login}>
            <AlertProvider>
                <div className="wrapper">
                    <div className="header">
                        <div className="header-menu-icon d-md-none" onClick={() => setShowMenu(!showMenu)}>
                            <img src={menuIcon} alt="menu bar" />
                        </div>
                        <div className="header-title d-flex align-items-center">
                            <img className="header-logo" src={mittelLogo} alt="mittel" />
                            <span className="d-none d-md-block">{paths[1] === ENUM.route.admin && "- Administrator"}</span>
                        </div>
                        <div className="header-menu">
                            <div className="header-menu-user d-none d-md-flex">
                                <img className="me-1" src={userIcon} alt="user" />
                                <span>{user && user.name}</span>
                            </div>
                            {paths[1] !== ENUM.route.admin ?
                                <>
                                    {isAdmin &&
                                        <Link to={'/admin' + ENUM.route.articles + "/1"} className="me-2">
                                            <Button variant="outline-primary">Admin Panel</Button>
                                        </Link>
                                    }
                                    {!isAdmin &&
                                        <Link to={ENUM.route.orders} className="me-2">
                                            <Button variant="outline-primary">Orders History</Button>
                                        </Link>
                                    }
                                </>
                                :
                                <Link to="/" className="me-2">
                                    <Button variant="outline-primary">Orders Panel</Button>
                                </Link>
                            }
                            <Button className="d-flex align-items-center" size="sm" onClick={logout}>
                                <img src={logoutIcon} alt="logout"/>
                                <span className="d-none d-md-block ms-1">Logout</span>
                            </Button>
                        </div>
                    </div>
                    <div className="content">
                        <ul className={`menu ${showMenu ? 'open' : ''}`}>
                            <Menu setShowMenu={setShowMenu} />
                        </ul>
                        <Switch>
                            <Route path={ENUM.route.brand + "/:brandId"} component={BrandPage}/>
                            <Route
                                path={ENUM.route.orders}
                                render={(props) => <OrdersHistoryPage {...props} userId={user.id} />}
                            />
                            {isAdmin &&
                                <ConfirmModalProvider>
                                    <Route path={'/admin' + ENUM.route.articles} component={ArticlesPage}/>
                                    <Route path={'/admin' + ENUM.route.orders} component={OrdersPage}/>
                                    <Route path={'/admin' + ENUM.route.users} component={Users}/>
                                    <Route path={'/admin' + ENUM.route.brands} component={BrandsPage}/>
                                    <Route path={'/admin' + ENUM.route.countries} component={Countries}/>
                                    <Route path={'/admin' + ENUM.route.importExport} component={ImportExportPage}/>
                                </ConfirmModalProvider>
                            }
                        </Switch>
                    </div>
                </div>
            </AlertProvider>
        </UserProvider>
    );
}

export default withRouter(
    fetchPromises({
        user: () => ({ name: 'getLoggedUser' })
    })(Mittel)
);
