export const ENUM = {
    route: {
        admin: "admin",
        articles: "/articles",
        orders: "/orders",
        users: "/users",
        brands: "/brands",
        countries: "/countries",
        brand: "/brand",
        importExport: "/importExport"
    }
}