import {Form} from "react-bootstrap";
import React from "react";
import {fetchPromises} from "../utils/fetchPromises.js";

function BrandCategoriesSelect({brandCategories, value, onInputChange, required}) {
    return (
        <Form.Select
            name="brandCategoryId"
            onChange={onInputChange}
            value={value || ''}
            required={required && brandCategories && brandCategories.length > 0}
        >
            <option key={0} value="" />
            {brandCategories && brandCategories.map((bc, index) =>
                <option key={index} value={bc.id}>{bc.name}</option>
            )}
        </Form.Select>
    );
}

export default fetchPromises({
    brandCategories: (props) => props.brandId && ({ name: 'getBrandCategoriesByBrandId', props: ['brandId'] }),
}, false)(BrandCategoriesSelect);
