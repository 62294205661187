import React, { useState, createContext } from 'react';
import {Button, Modal} from "react-bootstrap";


export const ConfirmModalContext = createContext(null);

export function ConfirmModalProvider({ children }) {
    const [store, setStore] = useState({});
    const { show, message, onConfirmClick } = store;

    const showConfirmModal = (message, onConfirmClick) => {
        setStore({
            show: true,
            message,
            onConfirmClick,
        });
    };

    const hideModal = () => {
        setStore({
            show: false
        });
    };

    const onConfirm = async () => {
        await onConfirmClick();
        hideModal();
    }

    return (
        <ConfirmModalContext.Provider value={{ showConfirmModal }}>
            {
                <Modal show={show}>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer className="d-flex">
                        <Button variant="outline-primary" onClick={hideModal}>Close</Button>
                        <Button onClick={onConfirm}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            }
            {children}
        </ConfirmModalContext.Provider>
    );
};