import React, {useState} from "react";
import {Button, Form} from "react-bootstrap";
import addIcon from "../../styles/assets/icons/add.svg";
import ArticleModal from "../../components/ArticleModal";
import ArticlesTable from "../../components/ArticlesTable";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {ENUM} from "../../utils/enum.js";
import {fetchPromises} from "../../utils/fetchPromises.js";

function ArticlesPage(props) {
    const {brands} = props;
    const [modal, setModal] = useState({
        show: false
    });
    const filter = queryString.parse(props.location.search);

    const onEditClick = async (event) => {
        setModal({
            show: true,
            articleId: +event.currentTarget.dataset.id
        });
    }

    const onSearchChange = (event) => {
        props.history.push({
            pathname: `/${ENUM.route.admin}${ENUM.route.articles}`,
            search: "?" + queryString.stringify({
                search: event.target.value
            })
        });
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title d-flex align-items-center p-2">
                    <div>Articles</div>
                    <div className="ms-2">
                        <Form.Control type="text" name="search" value={filter.search || ''} onChange={onSearchChange} />
                    </div>
                </div>
                <div className="page-header-actions">
                    <Button className="d-flex align-items-center" size="sm" onClick={onEditClick}>
                        <img className="me-1" src={addIcon} alt="add" />
                        <span>Add New</span>
                    </Button>
                </div>
            </div>
            <ArticlesTable onEditClick={onEditClick} filter={filter} />
            {modal.show &&
                <ArticleModal
                    articleId={modal.articleId}
                    setModal={setModal}
                    brands={brands}
                />
            }
        </div>
    );
}
export default withRouter(
    fetchPromises({
        brands: () => ({ name: 'getBrands' }),
    })(ArticlesPage)
);
