import {Button, Form, Modal} from "react-bootstrap";

export function MittelModal({ title, show, onClose, onCallback, children }) {
    const onModalSubmit = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const formTarget = event.currentTarget;

        if (formTarget.checkValidity() === false) {
            event.target.className += " was-validated";
            return;
        }

        onCallback(event);
    }

    return (
        <Modal show={show} size="lg">
            <Form noValidate onSubmit={onModalSubmit} className="needs-validation">
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="light" onClick={onClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}