import {useCallback, useState} from 'react';
import setWith from "lodash/setWith";

export default function useForm(data) {
    const [form, setForm] = useState(data || {});

    const onComponentChange = useCallback(_form => {
        setForm(prevForm => ({
            ...prevForm,
            ..._form
        }));
    }, []);

    const onNumberChange = useCallback(e => {
        if (/^\d*(\.\d{0,4})?$/.test(e.target.value)) {
            onInputChange(e);
        }
    });

    const onInputChange = useCallback(e => {
        const name = e.target.type === 'checkbox' ? e.target.value : e.target.name;
        let value;
        if (e.currentTarget && e.currentTarget.type === 'checkbox') {
            value = e.target.checked;
        } else if (e.currentTarget && e.currentTarget.type === 'file') {
            if (e.target.files.length > 0) {
                value = e.target.files[0];
            } else {
                return;
            }
        } else {
            value = e.target.value;
        }

        if (!name) {
            onComponentChange(value);
        } else {
            setForm(prevForm => setObjectDeepValue(prevForm, name, value));
        }
    }, [onComponentChange]);

    return {
        form,
        setForm,
        onInputChange,
        onComponentChange,
        onNumberChange
    };
}

function setObjectDeepValue(obj, path, value) {
    // This will shallow clone only the objects in the path that changed
    const objectCopy = {...obj};
    setWith(objectCopy, path, value, Object);
    return objectCopy;
}
