export function encodeBase64(file) {

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}
export function decodeBase64(dataString)  {
    const matches = dataString.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);

    if (matches.length !== 3) {
        return new Error('Invalid input string');
    }

    return {
        type: matches[1],
        data: Buffer.from(matches[2], 'base64')
    };
}

export function getImageType(imageBuffer) {
    const imageTypeDetected = imageBuffer.type.match(/\/(.*?)$/);
    return imageTypeDetected[1];
}

export function countFilters(filters) {
    const filterSet = Object.keys(filters).filter(key => filters[key] && key !== 'page');
    return filterSet.length;
}
