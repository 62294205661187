import {Col, Form, Row} from "react-bootstrap";
import {MittelModal} from "./Modal.js";
import React from "react";
import {APIClient} from "../utils/APIClient.js";
import useForm from "../hooks/useForm.js";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {fetchPromises} from "../utils/fetchPromises.js";
import {BrandCategories} from "./BrandCategories";

function BrandModal({setModal, brand, refreshBrands, brandCategories}) {;
    const {form, setForm, onInputChange, onComponentChange} = useForm({
        ...brand,
        brandCategories: brandCategories || []
    });

    const onModalSaveClick = async () => {
        try {
            await APIClient().saveBrand(form);
            refreshBrands();
        } catch (e) {
            console.log(e);
        }
    }

    const onModalClose = () => {
        setModal({
            show: false
        });
    }

    return (
        <MittelModal show onClose={onModalClose} onCallback={onModalSaveClick} title="Brand">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Name
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="name" onChange={onInputChange} value={form.name || ''} required />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Code
                </Form.Label>
                <Col sm="8">
                    <Form.Control type="text" name="code" onChange={onInputChange} value={form.code || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Allow Pallet Quantity
                </Form.Label>
                <Col sm="8">
                    <BootstrapSwitchButton
                        checked={!!form.allowPalletQuantity}
                        onlabel="Show"
                        offlabel="Hide"
                        width={100}
                        onChange={(checked) => {
                            setForm({
                                ...form,
                                allowPalletQuantity: checked
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="4">
                    Allow Pack Quantity
                </Form.Label>
                <Col sm="8">
                    <BootstrapSwitchButton
                        checked={!!form.allowPackQuantity}
                        onlabel="Show"
                        offlabel="Hide"
                        width={100}
                        onChange={(checked) => {
                            setForm({
                                ...form,
                                allowPackQuantity: checked
                            })
                        }}
                    />
                </Col>
            </Form.Group>
            <BrandCategories brandCategories={form.brandCategories} onComponentChange={onComponentChange} />
        </MittelModal>
    );
}

export default fetchPromises({
    brand: (props) => props.brandId && ({ name: 'getBrand', props: ['brandId'] }),
    brandCategories: (props) => props.brandId && ({ name: 'getBrandCategoriesByBrandId', props: ['brandId'] }),
}, false)(BrandModal);