import {Button, Modal, Table} from "react-bootstrap";
import {fetchPromises} from "../../utils/fetchPromises";
import {withRouter} from 'react-router-dom';
import moment from "moment";
import React, {useState} from "react";
import {APIClient} from "../../utils/APIClient.js";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function OrdersHistoryPage({orders}) {
    const [showModal, setShowModal] = useState(false);
    const [details, setDetails] = useState([]);

    const onDetailsClick = async (event) => {
        const data = await APIClient().getOrderExport(+event.currentTarget.dataset.id);
        setDetails(data);
        setShowModal(true);
    }

    const onExportClick = async (orderId) => {
        const order = await APIClient().getOrderExport(orderId);
        const exportOrder = [];

        order.forEach((o) => {
            exportOrder.push({
                'Article Code': o.code,
                'Article EAN Code': o.eanCodePc,
                'Article name': o.name,
                'Quantity (Pcs)': o.quantityPieces
            });
        });

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(exportOrder);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "order_" + orderId + fileExtension);
    }

    return (
        <div className="w-100 d-flex flex-column overflow-hidden">
            <div className="page-header p-1 d-flex justify-content-between align-items-center">
                <span className="fs-5">Orders</span>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>Brand</th>
                        <th>Created</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orders && orders.map((o, index) =>
                        <tr key={index}>
                            <td>{o.brand}</td>
                            <td>{moment(o.created).format("DD.MM.YYYY HH:mm:ss")}</td>
                            <td className="text-center table-actions">
                                <Button
                                    className="table-actions-btn btn btn-primary"
                                    size="sm"
                                    variant="primary"
                                    onClick={onDetailsClick}
                                    data-id={o.id}
                                >
                                    <span className="d-none d-md-flex">Details</span>
                                </Button>
                                <Button className="table-actions-btn btn-primary btn-md" size="sm" variant="info" onClick={(e) =>onExportClick(o.id)}>
                                    <span className="d-none d-md-flex">Export</span>
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            <Modal show={showModal} size="lg">
                <Modal.Body>
                    <Table striped hover className="mb-0 table-va-middle">
                        <thead>
                        <tr>
                            <th>Article Code</th>
                            <th>Article EAN Code</th>
                            <th>Article Name</th>
                            <th className="text-end">Quantity (Pcs)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {details && details.map((d, index) =>
                            <tr key={index}>
                                <td>{d.code}</td>
                                <td>{d.eanCodePc}</td>
                                <td>{d.name}</td>
                                <td className="text-end">{d.quantityPieces}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className="d-flex">
                    <Button variant="outline-primary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default withRouter(fetchPromises({
    orders: () => ({ name: 'getOrders', props: ['userId'] })
})(OrdersHistoryPage));
