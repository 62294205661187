import {Form} from "react-bootstrap";
import fileIcon from "../styles/assets/icons/file.svg";
import noImageIcon from "../styles/assets/icons/no-image.svg";

export default function BrandTableRows({rows, form, filter, showDetails, onInputChange}) {
    return (
        rows.filter(filterRows(filter)).map((i, index) =>
            <tr key={index}>
                <td className="no-break">{i.code}</td>
                <td className="no-break">{i.name}</td>
                <td className="no-break">{i.eanCodePc}</td>
                <td className="no-break">{i.brandCategoryName}</td>
                <td className="color-orange no-break">{i.family}</td>
                <td className="no-break">{i.sector}</td>
                <td>{i.shelfLife}</td>
                <td>{i.pcsPerPack}</td>
                <td>{i.pcsPerPallet}</td>
                <td>{i.palletLayers}</td>
                <td>{i.packsPerLayer}</td>
                {showDetails &&
                    <>
                        <td>{i.description}</td>
                        <td>{i.tariffCode}</td>
                        <td>{i.brandCode}</td>
                        <td>{i.pieceVolumeGrossMl}</td>
                        <td>{i.pieceVolumeNetMl}</td>
                        <td>{i.pieceWeightGrossKG}</td>
                        <td>{i.pieceWeightNetKG}</td>
                        <td>{i.packWeightGross}</td>
                        <td>{i.packWeightNet}</td>
                        <td>{i.palletWeightGross}</td>
                        <td>{i.palletWeightNet}</td>
                        <td>{i.pcLength}</td>
                        <td>{i.pcDepth}</td>
                        <td>{i.pcHeight}</td>
                        <td>{i.packLength}</td>
                        <td>{i.packDepth}</td>
                        <td>{i.packHeight}</td>
                        <td>{i.palletHeight}</td>
                        <td>{i.eanCodePack}</td>
                        <td>{i.eanCodePallet}</td>
                        <td className="text-center">
                            {i.specification ?
                                <a href={i.specification} target="_blank" rel="noreferrer">
                                    <img src={fileIcon} alt="file download"/>
                                </a> : null
                            }
                        </td>
                        <td className="align-middle text-center">
                            {i.image ?
                                <a href={i.image} target="_blank" rel="noreferrer">
                                    <img className="wp100" src={i.image} height="30" alt="Product"/>
                                </a>
                                :
                                <img src={noImageIcon} height="26" alt="Product"/>
                            }
                        </td>
                    </>
                }
                {i.allowPalletQuantity ?
                    <td className="color-blue align-middle">
                        <Form.Control
                            className="w100"
                            type="number"
                            name={`pallets[${i.id}].quantity`}
                            value={form.pallets[i.id].quantity}
                            onChange={onInputChange}
                            min={0}
                        />
                    </td> : null
                }
                {i.allowPackQuantity ?
                    <td className="color-blue align-middle">
                        <Form.Control
                            className="w100"
                            type="number"
                            name={`packs[${i.id}].quantity`}
                            value={form.packs[i.id].quantity}
                            onChange={onInputChange}
                            min={0}
                        />
                    </td> : null
                }
                <td className="color-blue align-middle">
                    <Form.Control
                        className="w100 readonly"
                        type="number"
                        name={`packs[${i.id}].quantity`}
                        value={form.pieces[i.id].quantity}
                        readOnly
                    />
                </td>
            </tr>
        )
    );
}

function filterRows(filter) {
    return i => {
        let includes = true;
        if (filter.code && i.code) {
            includes &= i.code.toLowerCase().includes(filter.code.toLowerCase());
        }
        if (filter.name && i.name) {
            includes &= i.name.toLowerCase().includes(filter.name.toLowerCase());
        }
        if (filter.description && i.description) {
            includes &= i.description.toLowerCase().includes(filter.description.toLowerCase());
        }
        if (filter.eanCode && i.eanCodePc) {
            includes &= i.eanCodePc.toLowerCase().includes(filter.eanCode.toLowerCase());
        }
        if (filter.family && i.family) {
            includes &= i.family.toLowerCase().includes(filter.family.toLowerCase());
        }
        if (filter.sector && i.sector) {
            includes &= i.sector.toLowerCase().includes(filter.sector.toLowerCase());
        }
        if (filter.brandCategoryName && i.brandCategoryName) {
            includes &= i.brandCategoryName.toLowerCase().includes(filter.brandCategoryName.toLowerCase());
        }

        return includes;
    };
}
