import {Button, Table} from "react-bootstrap";
import trashIcon from "../../styles/assets/icons/trash.svg";
import editIcon from "../../styles/assets/icons/edit.svg";
import addIcon from "../../styles/assets/icons/add.svg";
import {useContext, useState} from "react";
import {fetchPromises} from "../../utils/fetchPromises";
import {APIClient} from "../../utils/APIClient";
import {ConfirmModalContext} from "../../components/ConfirmModalProvider.js";
import BrandModal from "../../components/BrandModal";

function BrandsPage({ brands, refresh }) {
    const [modal, setModal] = useState({
        show: false
    });
    const {showConfirmModal} = useContext(ConfirmModalContext);

    const onDeleteClick = async (event) => {
        const id = +event.currentTarget.dataset.id;
        showConfirmModal("Are you sure to delete brand?", async () => {
            try {
                await APIClient().deleteBrand(id);
                refresh();
            } catch (e) {}
        });
    }

    const onEditClick = async (event) => {
        setModal({
            show: true,
            brandId: +event.currentTarget.dataset.id
        });
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Brands</div>
                <div className="page-header-actions">
                    <Button className="d-flex align-items-center" size="sm" onClick={onEditClick}>
                        <img className="me-1" src={addIcon} alt="add" />
                        <span>Add New</span>
                    </Button>
                </div>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {brands && brands.map((b, index) =>
                            <tr key={index}>
                                <td>{b.name}</td>
                                <td className="text-center table-actions">
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="primary"
                                        onClick={onEditClick}
                                        data-id={b.id}
                                    >
                                        <img src={editIcon} alt="edit" className="d-block" />
                                    </Button>
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="danger"
                                        onClick={onDeleteClick}
                                        data-id={b.id}
                                    >
                                        <img src={trashIcon} alt="delete" className="d-block" />
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {modal.show &&
                <BrandModal
                    brandId={modal.brandId}
                    setModal={setModal}
                    refreshBrands={refresh}
                />
            }
        </div>
    );
}
export default fetchPromises({
    brands: () => ({ name: 'getBrands' })
})(BrandsPage);
