import React from "react";

export default function Image({image, height = 35}) {
    if (image) {
        let imagePath = image;
        if (image.data) {
            imagePath = URL.createObjectURL(image.data);
        } else if (image.filepath) {
            imagePath = image.filepath;
        }
        return <img className="wp100" alt="Product" height={height} src={imagePath}/>;
    }
    return null;
}