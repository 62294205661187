import {Button, Col, Form, Row} from "react-bootstrap";
import {MittelModal} from "./Modal.js";
import React, {useState} from "react";
import {encodeBase64} from "../utils/helper.js";
import {APIClient} from "../utils/APIClient.js";
import useForm from "../hooks/useForm.js";
import fileIcon from "../styles/assets/icons/file.svg";
import Image from "./Image.js";
import {fetchPromises} from "../utils/fetchPromises.js";
import BrandCategoriesSelect from "./BrandCategorySelect";

function ArticleModal({countries, setModal, brands, article}) {
    const [showArticleCodes, setShowArticleCodes] = useState(false);
    const {form, onInputChange, onNumberChange} = useForm({
        brandId: brands && brands[0].id,
        ...article
    });

    const onModalSaveClick = async () => {
        try {
            const _form = {
                ...form,
                image: {
                    ...form.image,
                    data: form.image && form.image.data && await encodeBase64(form.image.data)
                },
                specification: {
                    ...form.specification,
                    data: form.specification && form.specification.data && await encodeBase64(form.specification.data)
                }
            }
            await APIClient().saveArticle(_form);

            setModal({
                show: false
            });
        } catch (e) {
            console.log(e);
        }
    }

    const onModalClose = () => {
        setModal({
            show: false
        });
    }

    const toggleArticleCodes = () => {
        setShowArticleCodes(!showArticleCodes);
    }

    const differentArticleCodes = (form.articleCodes && Object.keys(form.articleCodes).filter(i => form.articleCodes[i]).length) || 0;

    return (
        <MittelModal show onClose={onModalClose} onCallback={onModalSaveClick} title="Article">
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Name
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="name" value={form.name || ''} onChange={onInputChange} required />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Description
                </Form.Label>
                <Col sm="9">
                    <Form.Control as="textarea" rows={3} name="description" value={form.description || ''} onChange={onInputChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Brand
                </Form.Label>
                <Col sm="9">
                    <Form.Select name="brandId" onChange={onInputChange} value={form.brandId}>
                        {brands && brands.map((b, index) =>
                            <option key={index} value={b.id}>{b.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Brand Category
                </Form.Label>
                <Col sm="9">
                    <BrandCategoriesSelect
                        value={form.brandCategoryId}
                        brandId={form.brandId}
                        onInputChange={onInputChange}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Field is required.
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Code
                </Form.Label>
                <Col sm="5">
                    <Form.Control type="text" name="code" value={form.code || ''} onChange={onInputChange} />
                </Col>
                <Col sm="4">
                    <Button onClick={toggleArticleCodes}>Show All ({differentArticleCodes})</Button>
                </Col>
            </Form.Group>
            {showArticleCodes &&
                <div className="p-3 mb-3" style={{ background: "rgba(0,0,0,.05)" }}>
                    {countries && countries.map((c, index) =>
                        <Form.Group key={index} as={Row} className="mb-3">
                            <Form.Label column sm="3">
                                {c.name} Code
                            </Form.Label>
                            <Col sm="9">
                                <Form.Control
                                    type="text"
                                    name={`[articleCodes][${c.id}]`}
                                    value={(form.articleCodes && form.articleCodes[c.id]) || ''}
                                    onChange={onInputChange}
                                />
                            </Col>
                        </Form.Group>
                    )}
                </div>
            }
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Image
                </Form.Label>
                <Col className="fw130">
                    <Form.Control type="file" name="image.data" style={{width: "107px"}} onChange={onInputChange} />
                </Col>
                <Col sm="4">
                    <Image image={form.image} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Specification
                </Form.Label>
                <Col className="fw130">
                    <Form.Control type="file" name="specification.data" style={{width: "107px"}} onChange={onInputChange} />
                </Col>
                <Col className="text-truncate d-flex align-items-center">
                    <File file={form.specification} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Piece
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Length</Form.Label>
                    <Form.Control type="text" name="pcLength" onChange={onInputChange} value={form.pcLength || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Depth</Form.Label>
                    <Form.Control type="text" name="pcDepth" onChange={onInputChange} value={form.pcDepth || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Height</Form.Label>
                    <Form.Control type="text" name="pcHeight" onChange={onInputChange} value={form.pcHeight || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Pack
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Length</Form.Label>
                    <Form.Control type="text" name="packLength" onChange={onInputChange} value={form.packLength || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Depth</Form.Label>
                    <Form.Control type="text" name="packDepth" onChange={onInputChange} value={form.packDepth || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Height</Form.Label>
                    <Form.Control type="text" name="packHeight" onChange={onInputChange} value={form.packHeight || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Pallet Height
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="palletHeight" onChange={onInputChange} value={form.palletHeight || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Pieces per
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Pack</Form.Label>
                    <Form.Control type="text" name="pcsPerPack" onChange={onInputChange} value={form.pcsPerPack || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Pallet</Form.Label>
                    <Form.Control type="text" name="pcsPerPallet" onChange={onInputChange} value={form.pcsPerPallet || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Pallet Layers
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="palletLayers" onChange={onInputChange} value={form.palletLayers || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Packs per Layer
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="packsPerLayer" onChange={onInputChange} value={form.packsPerLayer || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Shelf Life
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="shelfLife" onChange={onInputChange} value={form.shelfLife || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Family
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="family" onChange={onInputChange} value={form.family || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Sector
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="sector" onChange={onInputChange} value={form.sector || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Piece Volume (ml)
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Gross</Form.Label>
                    <Form.Control type="text" name="pieceVolumeGrossMl" onChange={onNumberChange} value={form.pieceVolumeGrossMl || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Net</Form.Label>
                    <Form.Control type="text" name="pieceVolumeNetMl" onChange={onNumberChange} value={form.pieceVolumeNetMl || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Piece Weight (kg)
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Gross</Form.Label>
                    <Form.Control type="text" name="pieceWeightGrossKG" onChange={onNumberChange} value={form.pieceWeightGrossKG || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Net</Form.Label>
                    <Form.Control type="text" name="pieceWeightNetKG" onChange={onNumberChange} value={form.pieceWeightNetKG || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Pack Weight (kg)
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Gross</Form.Label>
                    <Form.Control type="text" name="packWeightGross" onChange={onNumberChange} value={form.packWeightGross || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Net</Form.Label>
                    <Form.Control type="text" name="packWeightNet" onChange={onNumberChange} value={form.packWeightNet || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    Pallet Weight (kg)
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Gross</Form.Label>
                    <Form.Control type="text" name="palletWeightGross" onChange={onNumberChange} value={form.palletWeightGross || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Net</Form.Label>
                    <Form.Control type="text" name="palletWeightNet" onChange={onNumberChange} value={form.palletWeightNet || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 align-items-center">
                <Form.Label column sm="3">
                    EAN Code
                </Form.Label>
                <Col sm="3">
                    <Form.Label>Piece</Form.Label>
                    <Form.Control type="text" name="eanCodePc" onChange={onInputChange} value={form.eanCodePc || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Pack</Form.Label>
                    <Form.Control type="text" name="eanCodePack" onChange={onInputChange} value={form.eanCodePack || ''} />
                </Col>
                <Col sm="3">
                    <Form.Label>Pallet</Form.Label>
                    <Form.Control type="text" name="eanCodePallet" onChange={onInputChange} value={form.eanCodePallet || ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="3">
                    Tariff Code
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" name="tariffCode" onChange={onInputChange} value={form.tariffCode || ''} />
                </Col>
            </Form.Group>
        </MittelModal>
    );
}

export default fetchPromises({
    article: (props) => props.articleId && ({ name: 'getArticle', props: ['articleId'] }),
    countries: () => ({ name: 'getCountries' })
}, false)(ArticleModal);

function File({file}) {
    if (file && (file.data || file.filepath)) {
        let filename;
        if (file.data) {
            filename = file.data.name;
        } else if (file.filepath) {
            const split = file.filepath.split('/');
            filename = split[split.length - 1];
        }

        return (
            <>
                <img src={fileIcon} alt="file" className="me-1" />
                <span className="text-truncate">{filename}</span>
            </>
        );
    }
    return null;
}
