import {Button, Col, Form, Row} from "react-bootstrap";
import trashIcon from "../styles/assets/icons/trash.svg";
import addIcon from "../styles/assets/icons/add.svg";
import React from "react";

export function BrandCategories({ brandCategories, onComponentChange }) {

    const onAddClick = () => {
        const newBrandCategories = brandCategories.slice();
        newBrandCategories.push({});

        onComponentChange({
            brandCategories: newBrandCategories
        });
    }

    const onDeleteClick = (event) => {
        const index = +event.currentTarget.dataset.index;
        const newBrandCategories = brandCategories.slice();
        newBrandCategories.splice(index, 1);

        onComponentChange({
            brandCategories: newBrandCategories
        });
    }

    const onChange = (event) => {
        const index = +event.currentTarget.dataset.index;
        const id = event.currentTarget.dataset.id;
        const newBrandCategories = brandCategories.slice();
        newBrandCategories[index] = {
            id,
            name: event.target.value
        };

        onComponentChange({
            brandCategories: newBrandCategories
        });
    }

    return (
        <>
            <h6>Brand Categories</h6>
            <hr />
            {brandCategories && brandCategories.map((bc, index) =>
                <Form.Group key={index} as={Row} className="mb-3">
                    <Col sm="10">
                        <Form.Control
                            type="text"
                            name="name"
                            onChange={onChange}
                            value={bc.name || ''}
                            data-id={bc.id}
                            data-index={index}
                        />
                    </Col>
                    <Col sm="2">
                        <Button size="md" variant="danger" onClick={onDeleteClick} data-index={index}>
                            <img src={trashIcon} alt="remove" />
                        </Button>
                    </Col>
                </Form.Group>
            )}
            <Row>
                <Col sm="2" className="offset-10">
                    <Button size="md" onClick={onAddClick}>
                        <img src={addIcon} alt="add" />
                    </Button>
                </Col>
            </Row>
        </>
    );
}