import {Button, Form} from "react-bootstrap";
import exportIcon from "../../styles/assets/icons/export.svg";
import importIcon from "../../styles/assets/icons/import.svg";
import {APIClient} from "../../utils/APIClient.js";
import React, {useContext, useState} from "react";
import crypto from "crypto";
import AlertContext from "../../contexts/alertContext.js";

export default function ImportExportPage() {
    const alert = useContext(AlertContext);
    const [state, setState] = useState({
        showLoading: false,
        log: [],
        selectedFile: undefined,
        fileKey: crypto.randomBytes(20).toString('hex'),
    });

    const onImportClick = async () => {
        if (state.selectedFile) {
            try {
                setState({
                    ...state,
                    showLoading: true,
                    log: [],
                });
                const formData = new FormData();
                formData.append("file", state.selectedFile);
                const log = await APIClient().importFile(formData);
                setState({
                    showLoading: false,
                    log,
                    selectedFile: undefined,
                    fileKey: crypto.randomBytes(20).toString('hex'),
                });
            } catch (error) {
                setState({
                    ...state,
                    showLoading: false,
                    log: [{ message: JSON.stringify(error), error: true }],
                });
            }
        } else {
            alert.error("There is no import file selected");
        }
    }

    const onExportClick = async () => {
        window.open('/api/file/export');
    }

    const onFileChange = async (event) => {
        setState({
            ...state,
            selectedFile: event.target.files[0]
        })
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Import / Export</div>
            </div>
            <div className="page-content p-2">
                <h4>Export</h4>
                <hr />
                <Button className="d-flex align-items-center" size="sm" onClick={onExportClick}>
                    <img src={exportIcon} alt="export"/>
                    <span className="d-none d-md-block ms-1">Export</span>
                </Button>
                <h4 className="mt-4">Import</h4>
                <hr />
                <div className="alert-primary p-2 mb-2">
                    Select the file to import and press the button "Import". Wait until the process is finished.<br />
                    Structure of the import file must be same as the one from export. Articles noted as error won't be updated or inserted. Correct the import file and try to reimport it.
                </div>
                <div className="d-inline-flex">
                    <Form.Control type="file" name="file" onChange={onFileChange} key={state.fileKey} />
                    <Button className="d-flex align-items-center ms-2" size="sm" onClick={onImportClick}>
                        <img src={importIcon} alt="import" style={{fill: "#fff", color: "#fff"}}/>
                        <span className="d-none d-md-block ms-1">Import</span>
                    </Button>
                </div>
                { state.showLoading ? (
                    <div className="spinner pr">
                        <div className="spinner-border" role="status" />
                    </div>
                    ) : null
                }
                {state.log && state.log.length > 0 ?
                    <div className="p-1 mt-2" style={{ background: "rgba(0,0,0,.1)", border: "1px solid rgba(0,0,0,.2)", fontSize: "10px" }}>
                        {state.log.map(l => <p className="m-0" style={{ color: l.error ? "red" : "green" }}>{l.message}</p>)}
                    </div> : null
                }
            </div>
        </div>
    );
}