import React from 'react';
import { usePagination, DOTS } from '../hooks/usePagination';
import {Pagination} from "react-bootstrap";

export default function MittelPagination({
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
}) {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className="page-bottom d-flex justify-content-center align-items-center p-2">
            <Pagination className="m-0">
                <Pagination.Prev disabled={currentPage === 1} onClick={onPrevious} />
                {paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <Pagination.Ellipsis key={index} />;
                    }

                    return (
                        <Pagination.Item
                            key={index}
                            active={pageNumber === currentPage}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}
                <Pagination.Next disabled={currentPage === lastPage} onClick={onNext} />
            </Pagination>
        </div>
    );
};