import {createContext, useState} from 'react';
import Login from "../pages/login.js";

export const UserContext = createContext(null);

export const UserProvider = (props) => {
    const [user] = useState(props.user);
    return (
        <UserContext.Provider value={user}>
            {!user ?
                <Login login={props.login}/>
                :
                props.children
            }
        </UserContext.Provider>
    );
};