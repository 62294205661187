import {APIClient} from "../utils/APIClient.js";
import crypto from "crypto";

export default function useAuth(location, history) {

  const getUser = async () => {
      return await APIClient().getLoggedUser();
  }

  const logout = async () => {
      await APIClient().logout();
      window.location.href = '/';
  }

  const login = async (username, password) => {
      if (username && password) {
          const shaSum = crypto.createHash('sha1');
          shaSum.update(password);
          const passwordHex = shaSum.digest('hex');
          const user = await APIClient().login(username, passwordHex);
          const paths = location.pathname.split('/');
          if (user && user.admin !== 'true' && paths[1] === 'admin') {
              history.push('/');
          }
          return user;
      }
  }

  return {
    login,
    logout,
    getUser,
  }
}
