export function APIClient() {
    return {
        login: (username, password) => sendRequest('/auth/login', 'POST', {
            username,
            password
        }),
        logout: () => sendRequest('/auth/logout'),
        getLoggedUser: () => sendRequest('/auth/get'),

        /* Brand */
        getBrands: () => sendRequest('/api/brands'),
        getBrandsWithBrandCategories: () => sendRequest('/api/brandsWithCategories'),
        getBrandsByUserId: (userId) => sendRequest('/api/brands/' + userId),
        deleteBrand: (brandId) => sendRequest('/api/brand/' + brandId, 'DELETE'),
        saveBrand: (data) => sendRequest('/api/brand', 'PUT', data),
        getBrand: (brandId) => sendRequest('/api/brand/' + brandId),
        getBrandCategoriesByBrandId: (brandId) => sendRequest('/api/brandCategories/' + brandId),

        /* Article */
        getArticlesByBrandId: (brandId) => sendRequest(`/api/brand/articles`, 'GET', {
            brandId
        }),
        getArticles: (search) => sendRequest(`/api/articles`, 'GET', search),
        deleteArticle: (articleId) => sendRequest('/api/article/' + articleId, 'DELETE'),
        saveArticle: (data) => sendRequest('/api/article', 'PUT', data),
        getArticle: (articleId) => sendRequest('/api/article/' + articleId),

        /* Order */
        createOrder: (data, userId, adminId) => sendRequest('/api/order', 'POST', {
            userId,
            adminId,
            ...data,
        }),
        getOrders: (userId) => sendRequest('/api/orders', 'GET', { userId }),
        getOrderExport: (orderId) => sendRequest(`/api/order/export/${orderId}`),

        /* User */
        getUsers: () => sendRequest('/api/users'),
        deleteUser: (userId) => sendRequest('/api/user/' + userId, 'DELETE'),
        saveUser: (data) => sendRequest('/api/user', 'PUT', data),
        getUser: (userId) => sendRequest('/api/user/' + userId),

        /* Country */
        getCountries: () => sendRequest('/api/countries'),
        deleteCountry: (countryId) => sendRequest('/api/country/' + countryId, 'DELETE'),
        saveCountry: (data) => sendRequest('/api/country', 'PUT', data),
        getCountry: (countryId) => sendRequest('/api/country/' + countryId),

        /* File */
        importFile: (data) => sendRequest('/api/file/import', 'POST', data, 'multipart/form-data'),
    };
}

function sendRequest(path, method = 'GET', data = {}, contentType = 'application/json') {
    const headers = new Headers();
    const parameters = {
        method: method,
        headers: headers,
    };

    let _path = path;
    if (method === 'GET' && data && Object.keys(data).length > 0) {
        const queryString = getQueryString(data);
        _path += '?' + queryString;
    }
    if (method !== 'GET') {
        parameters.body = data;
        if (contentType === 'application/json') {
            parameters.body = JSON.stringify(data);
            headers.append('Content-Type', contentType);
        }
    }

    return fetch(_path, parameters)
        .then((resp) => handleErrors(resp))
}

async function handleErrors(resp) {
    if (resp.ok) {
        if (resp.status === 200) {
            const contentType = resp.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const json = await resp.json();
                if (json.resolved === true) {
                    return json.data;
                } else {
                    console.log(json.error);
                    if (json.error && json.error.status === 403) {
                        window.location.href = '/';
                    }
                }
            }
        }
    }
}

function getQueryString(data) {
    return Object.keys(data).map((key) => {
        if (data[key]) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        }
        return '';
    }).join('&');
}
