import {withRouter} from "react-router-dom";
import {ENUM} from "../utils/enum";
import UserBrandsMenu from "./UserBrandMenu";
import {useContext} from "react";
import {UserContext} from "../contexts/userContext.js";

function Menu({ location, history, setShowMenu }) {
    const paths = location.pathname.split('/');
    const user = useContext(UserContext);

    const onClick = (event) => {
        if (location.pathname !== event.currentTarget.dataset.to) {
            history.push(event.currentTarget.dataset.to);
            setShowMenu(false);
        }
    };

    return (
        <>
            {paths[1] === ENUM.route.admin ?
                <>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.articles}/1`} className={paths[2] === ENUM.route.articles.substr(1) ? 'selected' : ''}>Articles</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.orders}`} className={paths[2] === ENUM.route.orders.substr(1) ? 'selected' : ''}>Orders</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.users}`} className={paths[2] === ENUM.route.users.substr(1) ? 'selected' : ''}>Users</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.brands}`} className={paths[2] === ENUM.route.brands.substr(1) ? 'selected' : ''}>Brands</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.countries}`} className={paths[2] === ENUM.route.countries.substr(1) ? 'selected' : ''}>Countries</li>
                    <li onClick={onClick} data-to={`/${ENUM.route.admin}${ENUM.route.importExport}`} className={paths[2] === ENUM.route.countries.substr(1) ? 'selected' : ''}>Import / Export</li>
                </>
                :
                <UserBrandsMenu userId={user && user.id} selectedBrandId={paths[2]} onClick={onClick} />
            }
        </>
    );
}
export default withRouter(Menu);
