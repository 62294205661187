import {Button, Table} from "react-bootstrap";
import trashIcon from "../../styles/assets/icons/trash.svg";
import editIcon from "../../styles/assets/icons/edit.svg";
import addIcon from "../../styles/assets/icons/add.svg";
import checkIcon from "../../styles/assets/icons/check.svg";
import {useContext, useState} from "react";
import {fetchPromises} from "../../utils/fetchPromises";
import {APIClient} from "../../utils/APIClient";
import {ConfirmModalContext} from "../../components/ConfirmModalProvider.js";
import UserModal from "../../components/UserModal";

function UsersPage({users, refresh}) {
    const [modal, setModal] = useState({
        show: false
    });
    const {showConfirmModal} = useContext(ConfirmModalContext);

    const onDeleteClick = async (event) => {
        const id = +event.currentTarget.dataset.id;
        showConfirmModal("Are you sure to delete user?", async () => {
            try {
                await APIClient().deleteUser(id);
                refresh();
            } catch (e) {}
        });
    }

    const onAddEditClick = async (event) => {
        setModal({
            show: true,
            userId: +event.currentTarget.dataset.id
        });
    }

    return (
        <div className="w-100 d-flex flex-column">
            <div className="page-header w-100 d-flex justify-content-between align-items-center">
                <div className="page-header-title p-2">Users</div>
                <div className="page-header-actions">
                    <Button className="d-flex align-items-center" size="sm" onClick={onAddEditClick}>
                        <img className="me-1" src={addIcon} alt="add" />
                        <span>Add New</span>
                    </Button>
                </div>
            </div>
            <div className="page-content">
                <Table striped hover className="mb-0 table-va-middle">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>External ID</th>
                        <th>Country</th>
                        <th className="text-center">Admin</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {users && users.map((u, index) =>
                            <tr key={index}>
                                <td>{u.name}</td>
                                <td>{u.externalId}</td>
                                <td>{u.country}</td>
                                <td className="text-center">{u.admin === 'true' ? <img src={checkIcon} alt="checked" /> : null}</td>
                                <td className="text-center table-actions">
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="primary"
                                        onClick={onAddEditClick}
                                        data-id={u.id}
                                    >
                                        <img src={editIcon} alt="edit" className="d-block" />
                                    </Button>
                                    <Button
                                        className="table-actions-btn"
                                        size="md"
                                        variant="danger"
                                        onClick={onDeleteClick}
                                        data-id={u.id}
                                    >
                                        <img src={trashIcon} alt="delete" className="d-block" />
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {modal.show &&
                <UserModal
                    userId={modal.userId}
                    setModal={setModal}
                    refreshUsers={refresh}
                />
            }
        </div>
    );
}
export default fetchPromises({
    users: () => ({ name: 'getUsers' })
})(UsersPage);
