import React, {useState} from 'react';
import useForm from "../hooks/useForm.js";
import {Button, Col, Form} from "react-bootstrap";
import logo from "../styles/assets/mittel.jpg"

export default function Login({login}) {
    const {form, onInputChange} = useForm({});
    const [showError, setShowError] = useState(false);

    const onSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const user = await login(form.username, form.password);
        if (user) {
            window.location.reload(false);
        } else {
            setShowError(true);
        }
    }

    return(
        <Form onSubmit={onSubmit} className="login-wrapper">
            <img src={logo} alt="logo" width={120} className="mb-3" />
            <Form.Group className="mb-3">
                <Form.Label>
                    Username
                </Form.Label>
                <Col>
                    <Form.Control type="text" name="username" onChange={onInputChange} value={form.username || ''} />
                </Col>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>
                    Password
                </Form.Label>
                <Col>
                    <Form.Control type="password" name="password" onChange={onInputChange} value={form.password || ''} />
                </Col>
            </Form.Group>
            {showError && <div className="alert-danger p-2 mb-3">Invalid username or password.</div>}
            <Button type="submit">Submit</Button>
        </Form>
    )
}
